'use strict';

angular.module('enervexSalesappApp').controller('AttachmentCtrl', function($scope, $state, $stateParams, $q, AttachmentsService, Account, Job, User, FileUploader, Auth, Util, _) {
	$scope.jobLabel = Util.jobLabel;
	$scope.accountId = $stateParams.accountId;
	$scope.jobId = $stateParams.jobId;
	$scope.attachmentId = $stateParams.attachmentId;
	$scope.upload= {};
	$scope.me = Auth.getCurrentUser

	$scope.job = Job.get({
		id: $stateParams.jobId,
		accountId: $stateParams.accountId
	}).$promise.then(function(job){
		$scope.job = job;
		$scope.isInternalMember = Auth.isInternalMember(job);
		if ($stateParams.attachmentId != 'new'){
			fetchComments();
			getAttachment();
			getAttachmentVersions();
		} else {
			$scope.attachment = {
				visibility: Util.defaultVisibility($scope.job),
				notifyMembers:[]
			}
			$scope.target = $scope.attachment;
			Util.setNotifyMembersType($scope.attachment, $scope.job)
		}
	});
	Account.get({
		id: $stateParams.accountId
	}).$promise.then(function(res){
		$scope.account = res;
	});
	if ($stateParams.mode == "edit") {
		$scope.mode = "edit"
	}
	if ($scope.mode === 'edit'){
		$scope.editForm = true;
	}
	$scope.editState = function(){
		// $scope.mode = "edit";
		$state.go('attachment', {
			accountId: $scope.accountId,
			jobId: $scope.jobId,
			attachmentId: $scope.attachmentId,
			mode: 'edit',
		}, {
			inherit: false,
			reload: true
		});
	}

	$scope.previousVersion = function(version){
		var latestV = _.max($scope.attachmentVersions, function(attachment){
			return attachment.version;
		});
		if (version != latestV) {
			// $scope.mode = "version";
			$state.go('attachment', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
				attachmentId: $scope.attachmentId,
				version: version.version, 
				mode:"version"
			}, {
				inherit: false,
				reload: true
			});
			// $scope.attachment = version;
		} else {
			// $scope.mode = '';
			$state.go('attachment', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
				attachmentId: $scope.attachmentId,
				version: null, 
				mode:null
			}, {
				inherit:false,
				reload: true
			});
			// $scope.attachment = version;
		}
	}


	function getAttachmentVersions(){
		AttachmentsService.getAttachmentVersions().$promise.then(function(res){
			$scope.attachmentVersions = res;
		})
	}

	function getAttachment() {
		AttachmentsService.fetchAttachment().$promise.then(function(attachment){
			$scope.attachment = attachment;
			$scope.target = $scope.attachment;
			Util.setNotifyMembersType($scope.attachment, $scope.job)
			$scope.checkedMembers = attachment.explicitMembers;

			if ($stateParams.version != undefined) {
				var version = Number($stateParams.version);
				AttachmentsService.getAttachmentVersions().$promise.then(function(res){
					$scope.attachmentVersions = res;
					_.each($scope.attachmentVersions, function(attachment){
						if (attachment.version == version) {
							$scope.previousVersion(attachment);
						}
					})
				})
			}
		});
	}
	$scope.checkedMembers = [];
	$scope.change = function() {
		$scope.formChanged = true;
	}
	$scope.toggleCheck = Util.toggleCheck;
	$scope.memberOf = Util.memberOf;
	$scope.checkedEmailMembers = [];
	$scope.toggleEmailCheck = function(member){
		$scope.formChanged = true;
		var memberId = member.user._id
		if ($scope.checkedEmailMembers.indexOf(memberId) === -1){
			$scope.checkedEmailMembers.push(member.user._id);
		} else {
			$scope.checkedEmailMembers.splice($scope.checkedEmailMembers.indexOf(memberId), 1);
		}
	}
	$scope.setChecks = false;
	$scope.selectAllEmails = function(){
		$scope.checkedEmailMembers = [];
		_.each($scope.job.members, function(member){
			$scope.checkedEmailMembers.push(member.user._id);
		})
		$scope.setChecks = true;
		console.log($scope.checkedEmailMembers)
	}
	$scope.deselectAllEmails = function(){
		$scope.checkedEmailMembers = [];
		$scope.setChecks = false;
	}
	var uploader = $scope.uploader = new FileUploader({});
	$scope.visibilityChanged = function() {
		console.log($scope.upload.visibility)
	}
	$scope.submitAttachment = function() {
		_.each($scope.uploader.queue, function(data){
			var fd = new FormData();
			fd.append('name', data._file.name);
			fd.append('asset', data._file);
			fd.append('visibility', $scope.attachment.visibility)
			if ($scope.attachment.visibility === 'explicit'){
				fd.append('explicitMembers', _.pluck($scope.checkedMembers, "_id").join(","))
			}
			if ($scope.attachment.notifyMembersType === 'all'){
				$scope.attachment.notifyMembers = _.map($scope.job.members, function(member){
					return member.user._id;
				});
			} else if ($scope.attachment.notifyMembersType === 'some') {
				$scope.attachment.notifyMembers = $scope.checkedEmailMembers;
			} else if ($scope.attachment.notifyMembersType === 'none'){
				$scope.attachment.notifyMembers = [];
			}
			fd.append('notifyMembers', $scope.attachment.notifyMembers.join(","))
			AttachmentsService.createAttachment(fd).$promise.then(function(res){
				$state.go('attachment', {
					accountId: $scope.accountId,
					jobId: $scope.jobId,
					attachmentId: res._id
				}, {
					inherit: false,
					reload: true
				});
			})
		});
	}

	$scope.updateAttachmentFile = function(){
		// console.log($scope.attachment)
		_.each($scope.uploader.queue, function(data){
			var fd = new FormData();
			fd.append('name', data._file.name);
			fd.append('asset', data._file);
			fd.append('visibility', $scope.attachment.visibility)
			if ($scope.attachment.visibility === 'explicit'){
				fd.append('explicitMembers',  _.pluck($scope.checkedMembers, "_id").join(","))
			}
			AttachmentsService.updateAttachment(fd).$promise.then(function(res){
				$state.go('attachment', {
					accountId: $scope.accountId,
					jobId: $scope.jobId,
					attachmentId: $scope.attachmentId,
				}, {
					inherit: false,
					reload: true
				});
			})
		});
	}
	$scope.cancelAttachmentEdit = function(){
		// getAttachment();
		$state.go('attachment', {
			accountId: $scope.accountId,
			jobId: $scope.jobId,
			attachmentId: $scope.attachmentId,
		}, {
			inherit: false,
			reload: true
		});
	}
	$scope.deleteAttachment = function(attachment){
		AttachmentsService.removeAttachment(attachment).$promise.then(function(res){
			$state.go('attachments', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
			}, {
				inherit: false,
				reload: true
			});
		})
	}
	$scope.saveAttachmentChange = function(attachment){
		if (attachment.visibility != 'explicit'){
			$scope.checkedMembers = [];
		}
		var fd = new FormData();
		fd.append('name', attachment.name);
		fd.append('visibility', attachment.visibility)
		if (attachment.visibility === 'explicit'){
			fd.append('explicitMembers', _.pluck($scope.checkedMembers,"_id").join(","))
		}

		AttachmentsService.updateAttachment(fd).$promise.then(function(res){
			getAttachment();
			$state.go('attachment', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
				attachmentId: $scope.attachmentId,
			}, {
				reload: true,
				inherit: false
			});
			getAttachmentVersions()
		});
	}
	function fetchComments(){
		AttachmentsService.getComments().$promise.then(function(comments){
			$scope.attachmentComments = comments;
		});
	}
	$scope.newComment = {
		notifyMembers: []
	};
	Util.setNotifyMembersType($scope.newComment, $scope.job)

	var uploader = $scope.uploader = new FileUploader({});
	$scope.submitComment = function(newComment) {
		if (newComment.notifyMembersType === 'all'){
			newComment.notifyMembers = _.map($scope.job.members, function(member){
				return member.user._id;
			});
		} else if (newComment.notifyMembersType === 'some' ) {
			newComment.notifyMembers = $scope.checkedEmailMembers;
		} else if (newComment.notifyMembersType === 'none'){
			newComment.notifyMembers = [];
		}
		if ($scope.uploader.queue.length > 0) {
			var promises = [];
			_.each($scope.uploader.queue,function(data){
				var fd = new FormData();
				fd.append('name', data._file.name);
				fd.append('asset', data._file);
				fd.append('visibility', $scope.attachment.visibility);
				promises.push(AttachmentsService.createAttachment(fd).$promise)
			});
			$q.all(promises).then(function(responses){
				newComment.attachments = responses;
				AttachmentsService.createComment(newComment).$promise.then(function(res) {
					$scope.newComment = {
						notifyMembers: []
					};
					Util.setNotifyMembersType($scope.newComment, $scope.job)
					$scope.uploader.queue = [];
					fetchComments();
				});
			})
		} else {
			AttachmentsService.createComment(newComment).$promise.then(function(res) {
				$scope.uploader.queue = [];
				$scope.newComment = {
					notifyMembers: []
				};
				Util.setNotifyMembersType($scope.newComment, $scope.job)
				fetchComments();
			});
		}
	}
	$scope.deleteComment = function(comment) {
		AttachmentsService.removeComment(comment).$promise.then(function(res){
			$scope.attachments = [];
			fetchComments();
		})
	}
	$scope.saveCommentChange = function(comment){
		AttachmentsService.updateComment(comment).$promise.then(function(res){
			fetchComments();
		});
	}
	$scope.deleteCommentAttachment = function(comment, attachment){
		var index = comment.attachments.indexOf(attachment)
		if (index > -1){
			comment.attachments.splice(index, 1);
		};
		var newAttachmentList = comment.attachments;
		AttachmentsService.updateComment(comment).$promise.then(function(res){
			fetchComments();
		});
	}
});

